<template>
<div>load</div>
 <!-- <button v-on:click='load_entry_data'>load data</button>  -->
</template>

<script>
import EntryForm from 'components/super/EntryForm.vue';
import {ENV_URL, MAIN_URL, STRIPE_PUBLISHABLE} from '../../environment.js';
import axios from 'axios';


export default {
  name: 'directory-view',
  data:{
    errors:[]
  },
  components: {
    EntryForm,
  },
  data () {
    console.log('current page', this.$route)
    return {
      myentrydata : [
  {
    "Surgeon_LastName": "Abrams",
    "Surgeon_FirstName": "Jeffrey",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "609-924-8131",
    "Practice_Name": "Princeton Orthopaedic Associates",
    "Address": "325 Princeton Avenue",
    "City": "Princeton",
    "State": "NJ",
    "Zip": "08540",
    "Specialty": "Shoulder Arthroscopic Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Abrutyn",
    "Surgeon_FirstName": "David",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "908-685-8500",
    "Practice_Name": "Summit Medical Group",
    "Address": "215 Union Avenue Suite B",
    "City": "Bridgewater",
    "State": "NJ",
    "Zip": "08807",
    "Specialty": "Sports Medicine Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Ashraf",
    "Surgeon_FirstName": "Nomaan",
    "Credentials": "MD MBA",
    "Website": "",
    "Video Link": "",
    "Phone": "201-634-1811",
    "Practice_Name": "Comprehensive Spine Care, PA",
    "Address": "260 Old Hook Road Suite 400",
    "City": "Westwood",
    "State": "NJ",
    "Zip": "07675",
    "Specialty": "Spine Minimally Invasive Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Atik",
    "Surgeon_FirstName": "Teddy",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "732-542-4477",
    "Practice_Name": "Central Jersey Hand Surgery",
    "Address": "2 Industrial Way West",
    "City": "Eatontown",
    "State": "NJ",
    "Zip": "07724",
    "Specialty": "Hand Open Repair & Reconstructive Surgery, Hand Arthroscopic Surgery, Hand Minimally Invasive Surgery, Wrist Open Repair & Reconstructive Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Benevenia",
    "Surgeon_FirstName": "Joseph",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "973-972-2151",
    "Practice_Name": "North Jersey Orthopaedic Institute",
    "Address": "205 South Orange Avenue",
    "City": "Newark",
    "State": "NJ",
    "Zip": "07103",
    "Specialty": "Oncologic Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Bernstein",
    "Surgeon_FirstName": "Steven",
    "Credentials": "DPM",
    "Website": "",
    "Video Link": "",
    "Phone": "201-482-8236",
    "Practice_Name": "Steven Bernstein DPM, FACFAS",
    "Address": "1608 Lemoine Avenue Suite 205",
    "City": "Fort Lee",
    "State": "NJ",
    "Zip": "07024",
    "Specialty": "Fore Mid Foot Open Repair & Reconstructive Surgery,Forefoot & Midfoot Arthroscopic Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Bloom",
    "Surgeon_FirstName": "Tamir",
    "Credentials": "MD, FAAOS",
    "Website": "pediatricorthopedics.com",
    "Video Link": "",
    "Phone": "973-538-7700",
    "Practice_Name": "The Pediatric Orthopedic Center, A Member of Consensus Health",
    "Address": "218 Ridgedale Avenue, Suite 101",
    "City": "Cedar Knolls",
    "State": "NJ",
    "Zip": "07927",
    "Specialty": "Pediatric Surgery",
    "Additional_Locations": "Wayne|Springfield |Jersey City",
    "Photo_ID": "Bloom_Tamir.jpg",
    "Description": "It is my belief that every child is a unique individual and I am committed to understanding how their conditions or injuries impact their lives and their relationships with parents, families and caregivers. I specialize in pediatric athlete injuries, pediatric fractures, clubfoot correction and other foot and ankle conditions, and have a special interest in neuromuscular conditions such as cerebral palsy and spina bifida. My goal is to understand each patient�s individual needs and challenges so that I can provide high-quality, personalized care to get him or her healthy and back into action as soon as possible. I also make a strong effort to educate young athletes on injury prevention and on how to take care of their growing bodies. I think my patients and their families appreciate that.\nAs a competitor in two Olympic Games myself (as a fencer for Team USA in 1996 and 2000) and having dealt with a significant sports injury myself, I can relate to my patients on a very personal level. I lived it and I know what it�s like. I remember how the injury impacted my performance, personal life and career decisions. From that experience, I understand how injuries and surgeries can affect young athletes, physically but also socially and emotionally. I am committed to making the journey to recovery as easy as possible for my patients. DR. TAMIR BLOOM is a Bone & Joint Select Surgeon honoree for being one of New Jersey�s top pediatric orthopedic surgeons.",
    "Enhanced": "TRUE"
  },
  {
    "Surgeon_LastName": "Chiappetta",
    "Surgeon_FirstName": "Gino",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "732-537-0909",
    "Practice_Name": "University Orthopaedic Associates",
    "Address": "Two Worlds Fair Drive",
    "City": "Somerset",
    "State": "NJ",
    "Zip": "08873",
    "Specialty": "Spine Open Repair & Reconstructive Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Cohen",
    "Surgeon_FirstName": "Marc A.",
    "Credentials": "MD",
    "Website": "marcacohenspinecenter.com",
    "Video Link": "",
    "Phone": "973-538-4444",
    "Practice_Name": "The Spine Institute",
    "Address": "221 Madison Avenue",
    "City": "Morristown",
    "State": "NJ",
    "Zip": "07960",
    "Specialty": "Spine Open Repair & Reconstructive Surgery, Spine Minimally Invasive Surgery",
    "Additional_Locations": "",
    "Photo_ID": "Cohen_Mark-A.jpg",
    "Description": "Spine surgery remains one of the most intriguing, complex and challenging fields. I have been performing this type of surgery for more than 30 years, focusing on conventional spine procedures and minimally invasive spine surgery. Besides the technical challenges of surgery, the clinical challenges of making the right diagnosis are very appealing to me. I carefully evaluate the problem, determine the pathology and access the complexity of the condition. The decision to consider spine surgery should always come after trying non-surgical or conservative options. However, when the pain is persistent and clearly related to an anatomical problem, then surgery is a reasonable option.\nAt my practice you will be matched with a patient advocate who will act as a medical concierge, guiding you through paperwork, preliminary testing and any healthcare requirements that are needed at each step of the surgical process. My level of specialty training at the Hospital for Special Surgery/Weill Cornell Medical Center and Memorial Sloan-Kettering Cancer Center as well as my focus on the spine has contributed to my enhancement in surgical technique, which, in turn, has led to an overall improved success rate in patient outcomes.\nThe right treatment plan begins with the right diagnosis. My patients can rely on precise clinical evaluation combined with state-of-the-art surgery. This provides patients with the peace of mind that they are receiving the best possible care and therefore better outcomes.\nMy passion is my family and the traveling we do together�nothing can beat that. DR. MARC A. COHEN is a Bone & Joint Select Surgeon honoree for being one of New Jersey�s top spine open repair and reconstruction and spine minimally invasive surgeons",
    "Enhanced": "TRUE"
  },
  {
    "Surgeon_LastName": "Corrigan",
    "Surgeon_FirstName": "Frank",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "908-684-3005",
    "Practice_Name": "The Orthopedic Institute of New Jersey",
    "Address": "376 Lafayette Road  Suite 202",
    "City": "Sparta",
    "State": "NJ",
    "Zip": "07871",
    "Specialty": "Wrist Open Repair & Reconstructive Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Cozzarelli",
    "Surgeon_FirstName": "John",
    "Credentials": "DPM, DABPS. FACFAS",
    "Website": "drfoot.tv",
    "Video Link": "https://www.dropbox.com/sh/ddlfowtgsgte86e/AAD4iQtUeU2QLAORmyaxoVnka?dl=0",
    "Phone": "973-450-0878",
    "Practice_Name": "Podiatry Associates of Belleville",
    "Address": "155 Belleville Avenue",
    "City": "Belleville",
    "State": "NJ",
    "Zip": "07109",
    "Specialty": "FORE MID FOOT MI",
    "Additional_Locations": "",
    "Photo_ID": "Cozzarelli_John.jpg",
    "Description": "I chose medicine because I enjoy helping people every day. Patients who become part of my practice receive quality treatment from a dedicated, conscientious doctor and a compassionate experienced staff who provide quality care. I have been in practice for 32 years and specialize in minimally invasive bone surgery, including bunionectomies, bone spurs and recalcitrant heel pain, as well as nerve surgery. If the patient has exhausted all conservative care and is ready to have surgery, I walk them through the entire procedure, including the day of surgery and post-operative care. Using surgical animations, the patient will be fully educated to understand their procedure from start to finish. Before the surgery, I review X-rays, MRI and diagnostic ultrasound, along with other tests like nerve conduction velocity (NCV) and an electromyogram (EMG), to determine the best surgical procedure to use.\nI was born in Newark and raised in Belleville, where my office is located. I had multiple offices throughout New York, but my goal was to always come back home and help residents in the neighborhood where I grew up. One of my proudest accomplishments is having been recognized as a New Jersey Monthly Top Doctor nine times. In addition to having board certification in foot surgery, I received training in microsurgery at NewYork-Presbyterian/Columbia University Irving Medical Center in New York City and in nerve restoration surgery at Johns Hopkins University in Baltimore. I also lecture extensively across the United States on the treatment and management of refractory gout and see patients at the Gout Institute of America, where infusions are performed. DR. JOHN COZZARELLI is a Bone & Joint Select Surgeon honoree for being one of New Jersey�s top forefoot and midfoot minimally invasive surgeons.",
    "Enhanced": "TRUE"
  },
  {
    "Surgeon_LastName": "Daniel",
    "Surgeon_FirstName": "Joseph",
    "Credentials": "DO",
    "Website": "",
    "Video Link": "",
    "Phone": "800-321-9999",
    "Practice_Name": "Rothman Orthopaedics Institute",
    "Address": "243 Hurffville Cross Keys Road",
    "City": "Sewell",
    "State": "NJ",
    "Zip": "08080",
    "Specialty": "Fore Mid Foot Open Repair & Reconstructive Surgery, Forefoot & Midfoot Arthroscopic Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Decker, Jr",
    "Surgeon_FirstName": "Raymond",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "732-542-4477",
    "Practice_Name": "Central Jersey Hand Surgery",
    "Address": "2 Industrial Way West 2nd Floor",
    "City": "Eatontown",
    "State": "NJ",
    "Zip": "07724",
    "Specialty": "Hand Arthroscopic Surgery, Hand Minimally Invasive Surgery, Wrist Minimally Invasive Surgery, Wrist Arthroscopic Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Doumas",
    "Surgeon_FirstName": "Christopher",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "732-938-6090",
    "Practice_Name": "University Orthopaedic Associates",
    "Address": "4810 Belmar Boulevard",
    "City": "Wall",
    "State": "NJ",
    "Zip": "07753",
    "Specialty": "Elbow Surgery, Hand Minimally Invasive Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Fahimi",
    "Surgeon_FirstName": "Nader",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "973-956-8100",
    "Practice_Name": "Elite Orthopedics and Sports Medicine",
    "Address": "342 Hamburg Turnpike Suite 209",
    "City": "Wayne",
    "State": "NJ",
    "Zip": "07470",
    "Specialty": "Hip Arthroscopic Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Fakharzadeh",
    "Surgeon_FirstName": "Frederick",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "201-587-7767",
    "Practice_Name": "Garden State Orthopaedic Associates",
    "Address": "22 Madison Avenue",
    "City": "Paramus",
    "State": "NJ",
    "Zip": "07652",
    "Specialty": "Hand Open Repair & Reconstructive Surgery, Wrist Open Repair & Reconstructive Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Garberina",
    "Surgeon_FirstName": "Matthew",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "908-273-4300",
    "Practice_Name": "Summit Medical Group",
    "Address": "1 Diamond Hill Road",
    "City": "Berkeley Heights",
    "State": "NJ",
    "Zip": "07922",
    "Specialty": "Shoulder Open Reconstructive & Replacement Surgery, Shoulder Arthroscopic Surgery, Elbow Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Gecha",
    "Surgeon_FirstName": "Steven",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "609-462-5242",
    "Practice_Name": "Princeton Orthopaedic Associates",
    "Address": "325 Princeton Avenue",
    "City": "Princeton",
    "State": "NJ",
    "Zip": "08540",
    "Specialty": "Knee Arthroscopic Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Goldman",
    "Surgeon_FirstName": "Robert",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "973-538-2334",
    "Practice_Name": "Tri-County Orthopedics",
    "Address": "197 Ridgedale Avenue PO Box 1446",
    "City": "Cedar Knolls",
    "State": "NJ",
    "Zip": "07927",
    "Specialty": "Hip Open Reconstructive & Replacement Surgery, Knee Open Reconstructive & Replacement Surgery, Knee Arthroscopic Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Green",
    "Surgeon_FirstName": "Aron",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "732-660-6200",
    "Practice_Name": "Seaview Orthopaedic and Medical Associates",
    "Address": "1200 Eagle Avenue",
    "City": "Ocean",
    "State": "NJ",
    "Zip": "07712",
    "Specialty": "Fore Mid Foot Open Repair & Reconstructive Surgery, Rearfoot Open Repair & Reconstructive Surgery, Rearfoot Arthroscopic Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Harrer",
    "Surgeon_FirstName": "Michael",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "856-821-6006",
    "Practice_Name": "Rothman Orthopaedics",
    "Address": "999 North Route 73 Suite 401",
    "City": "Marlton",
    "State": "NJ",
    "Zip": "08053",
    "Specialty": "Hip Open Reconstructive & Replacement Surgery, Knee Open Reconstructive & Replacement Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Hunt",
    "Surgeon_FirstName": "Stephen",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "973-538-2334",
    "Practice_Name": "Tri-County Orthopedics & Sports Medicine",
    "Address": "1590 Route 206 North",
    "City": "Bedminster",
    "State": "NJ",
    "Zip": "07921",
    "Specialty": "Hip Arthroscopic Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Jain",
    "Surgeon_FirstName": "Rajesh",
    "Credentials": "MD, MPH",
    "Website": "",
    "Video Link": "",
    "Phone": "609-267-9400",
    "Practice_Name": "Reconstructive Orthopedics",
    "Address": "4A Eves Drive Suite 100",
    "City": "Marlton",
    "State": "NJ",
    "Zip": "08053",
    "Specialty": "Knee Minimally Invasive Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Kanellakos",
    "Surgeon_FirstName": "James",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "973-538-0029",
    "Practice_Name": "Summit Medical Group Ortho",
    "Address": "95 Madison Avenue",
    "City": "Morristown",
    "State": "NJ",
    "Zip": "07960",
    "Specialty": "Shoulder Minimally Invasive Surgery, Hip Open Reconstructive & Replacement Surgery, Hip Minimally Invasive Surgery, Knee Open Reconstructive & Replacement Surgery, Trauma Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Kayal",
    "Surgeon_FirstName": "Robert",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "844-777-0910",
    "Practice_Name": "Kayal Orthopaedic Center",
    "Address": "784 Franklin Avenue Suite 250",
    "City": "Franklin Lakes",
    "State": "NJ",
    "Zip": "07417",
    "Specialty": "Shoulder Open Reconstructive & Replacement Surgery, Hip Arthroscopic Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Kim",
    "Surgeon_FirstName": "Richard",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "201-880-7766",
    "Practice_Name": "Richard Kim, MD",
    "Address": "113 West Essex Street Suite 203",
    "City": "Maywood",
    "State": "NJ",
    "Zip": "07607",
    "Specialty": "Hand Open Repair & Reconstructive Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Klein",
    "Surgeon_FirstName": "Gregg",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "201-291-4040",
    "Practice_Name": "Hartzband Center for Hip and Knee Replacement",
    "Address": "10 Forest Avenue Suite 200",
    "City": "Paramus",
    "State": "NJ",
    "Zip": "07652",
    "Specialty": "Hip Minimally Invasive Surgery, Knee Minimally Invasive Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Levine",
    "Surgeon_FirstName": "Harlan",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "201-291-4040",
    "Practice_Name": "Hartzband Center for Hip and Knee Replacement",
    "Address": "10 Forest Avenue Suite 200",
    "City": "Paramus",
    "State": "NJ",
    "Zip": "07652",
    "Specialty": "Hip Minimally Invasive Surgery. Knee Minimally Invasive Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Levine",
    "Surgeon_FirstName": "Stuart",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "606-924-8131",
    "Practice_Name": "Princeton Orthopaedic Associates",
    "Address": "325 Princeton Avenue",
    "City": "Princeton",
    "State": "NJ",
    "Zip": "08540",
    "Specialty": "Fore Mid Foot Open Repair & Reconstructive Surgery, Rearfoot Open Repair & Reconstructive Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Levitsky",
    "Surgeon_FirstName": "Kenneth",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "201-791-4434",
    "Practice_Name": "Garden State Orthopaedic Associates",
    "Address": "28-04 Broadway",
    "City": "Fair Lawn",
    "State": "NJ",
    "Zip": "07410",
    "Specialty": "Rearfoot Arthroscopic Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Lin",
    "Surgeon_FirstName": "David Y.",
    "Credentials": "MD, FAAOS",
    "Website": "pediatricorthopedics.com",
    "Video Link": "",
    "Phone": "973-538-7700",
    "Practice_Name": "The Pediatric Orthopedic Center, A Member of Consensus Health",
    "Address": "218 Ridgedale Avenue, Suite 101",
    "City": "Cedar Knolls",
    "State": "NJ",
    "Zip": "07927",
    "Specialty": "Pediatric Surgery",
    "Additional_Locations": "Wayne|Springfield |Jersey City",
    "Photo_ID": "Lin_David-Y.jpg",
    "Description": "I am part of one of the largest private pediatric orthopedic groups in the country and am fortunate to have partners that I can discuss, challenge and learn from. I have extensive expertise in ACL surgery, arthroscopic procedures of the knee and shoulder in children, and fracture care. Taking into consideration the child�s physical, mental and skeletal maturation, as well as social demands, I formulate a game plan to provide the patient with an optimal result and outcome. Many children I see are scared, apprehensive, anxious and even in pain. I try to alleviate their concerns by speaking with them on their level and forming a connection. I do listen to each patient�s individual situation and vary my treatments to accommodate their needs, as long as it is safe and doesn�t jeopardize their recovery or health. I respect their resiliency and ability to heal, and use it to my advantage in their management. Because all children are different, I do not treat them in a rote manner.\nThe most rewarding feeling is treating children with devastating injuries and returning them back to activities that they love while improving their quality of life, which can include sports, dance, music performance or just hanging out with friends. There is nothing better than a kid giving you a hug, a high five or fist bump, knowing they won their championship game or performed well in their showcase because you played a role in their recuperation.  DR. DAVID Y. LIN is a Bone & Joint Select Surgeon honoree for \nbeing one of New Jersey�s top pediatric orthopedic surgeons.",
    "Enhanced": "TRUE"
  },
  {
    "Surgeon_LastName": "Lombardi",
    "Surgeon_FirstName": "Paul",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "973-538-2334",
    "Practice_Name": "Tri-County Orthopaedic and Sports Medicine",
    "Address": "197 Ridgedale Avenue",
    "City": "Cedar Knolls",
    "State": "NJ",
    "Zip": "07927",
    "Specialty": "Hip Open Reconstructive & Replacement Surgery, Knee Open Reconstructive & Replacement Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Lowenstein",
    "Surgeon_FirstName": "Jason E.",
    "Credentials": "MD",
    "Website": "theadvancedspinecenter.com, jasonlowensteinmd.com",
    "Video Link": "Dr Lowenstein V2:�https://www.dropbox.com/s/gig9vsed9pprnh2/Jason%20E.%20Lowenstein%20%281%29.mov?dl=0",
    "Phone": "973-538-0900",
    "Practice_Name": "The Advanced Spine Center",
    "Address": "160 E. Hanover Avenue Suite 201",
    "City": "Morristown",
    "State": "NJ",
    "Zip": "07960",
    "Specialty": "Spine Open Repair & Reconstructive Surgery",
    "Additional_Locations": "Rockaway|Bridgewater",
    "Photo_ID": "Lowenstein_Jason-E.jpg",
    "Description": "Being diagnosed with scoliosis or spinal deformity can present a challenging situation for patients of all ages. As a board-certified orthopedic spine surgeon, I focus my practice on treating pediatric and adult patients with complex spinal disorders, such as scoliosis, kyphosis and spondylolisthesis. Some patients have newly discovered spinal deformities, while others have been living with theirs for many years. In either case, I strive to learn from each patient how their individual deformity is affecting their life, and then formulate a treatment plan to help that patient best achieve his or her personal goals. Ultimately, my hope is to maximally correct my patients spinal deformities, using minimally invasive techniques whenever possible, to allow them to recover and return to their normal activities as quickly as possible.\nPatients who have had previous surgery for scoliosis and require further treatment or have had previous spine surgery and have developed subsequent spinal deformity present a unique challenge. Often times these patients may have neurological disorders such as weakness or a foot drop. Additionally, there is frequently abundant scar tissue associated with the previous surgical approaches which must be delicately treated. As a complex spine surgeon, my practice focuses on these unusual revision cases. By carefully evaluating and accurately diagnosing the cause of their symptoms, these challenging patients can be successfully treated, alleviating their pain, improving their function and allowing them to enjoy a better overall quality of life. DR. JASON E. LOWENSTEIN is a Bone & Joint Select Surgeon \nhonoree for being one of New Jersey�s top spine open repair and reconstruction surgeons.",
    "Enhanced": "TRUE"
  },
  {
    "Surgeon_LastName": "Mark",
    "Surgeon_FirstName": "Arthur",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "732-660-6200",
    "Practice_Name": "Seaview Orthopaedic and Medical Associates",
    "Address": "1200 Eagle Avenue",
    "City": "Ocean",
    "State": "NJ",
    "Zip": "07712",
    "Specialty": "Hip Open Reconstructive & Replacement Surgery, Knee Open Reconstructive & Replacement Surgery, Knee Arthroscopic Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Matarese",
    "Surgeon_FirstName": "William",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "973-595-7779",
    "Practice_Name": "High Mountain Orthopedics",
    "Address": "342 Hamburg Turnpike Suite 205",
    "City": "Wayne",
    "State": "NJ",
    "Zip": "07470",
    "Specialty": "Shoulder Arthroscopic Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "McBride",
    "Surgeon_FirstName": "Mark",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "973-538-2334",
    "Practice_Name": "Tri-County Orthopedics",
    "Address": "197 Ridgedale Avenue Suite 300",
    "City": "Cedar Knolls",
    "State": "NJ",
    "Zip": "07927",
    "Specialty": "Elbow Surgery, Hand Open Repair & Reconstructive Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Meagher",
    "Surgeon_FirstName": "R.J.",
    "Credentials": "MD",
    "Website": "princetonbrainandspine.com",
    "Video Link": "",
    "Phone": "856-336-9808",
    "Practice_Name": "Princeton Brain and Spine",
    "Address": "199 Mullica Hill Road",
    "City": "Mullica Hill",
    "State": "NJ",
    "Zip": "08062",
    "Specialty": "Spine Minimally Invasive Surgery",
    "Additional_Locations": "",
    "Photo_ID": "Meagher_RJ.jpg",
    "Description": "Some might find it ironic, but most surgeons try to treat patients using non-surgical treatment options. However, some patients will require surgery for adequate treatment of their symptoms. I specialize in the treatment of conditions such as herniated discs and spinal stenosis. I try to devise minimally invasive approaches for anyone that I think warrants spinal surgery. I�ve worked hard to become an expert in surgical techniques that I think are much better for my patients.\nI can determine if they are candidates for surgery by listening to the history of their symptoms and their previous treatment, and correlating that to their examination findings and diagnostic tests. The greater the correlation, the more likely surgery will be successful.\nMy practice has adopted the following core values: care, compassion, professionalism, education, integrity, innovation and accountability. I encourage all personnel, from front office staff to billing, to consider these values every day at work. One of my personal strengths is being a good listener. I want my patients to know that I�m personally invested in their welfare and that I am grateful that they entrust me with their care. The goal is to have patients leave my office satisfied that they have a great team working to make them feel better. Any professional can get caught up in the daily grind, but it�s especially important for physicians to bear in mind that our job is also a special privilege. I never forget that. DR. R.J. MEAGHER is a Bone & Joint Select Surgeon honoree for being one of New Jersey�s top spine minimally invasive surgeons.",
    "Enhanced": "TRUE"
  },
  {
    "Surgeon_LastName": "Napoli",
    "Surgeon_FirstName": "Ralph",
    "Credentials": "DPM",
    "Website": "",
    "Video Link": "",
    "Phone": "201-358-0707",
    "Practice_Name": "Active Orthopedics & Sports Medicine",
    "Address": "67 Broadway��",
    "City": "Elmwood Park",
    "State": "NJ",
    "Zip": "07407",
    "Specialty": "Rearfoot Open Repair & Reconstructive Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Naseef",
    "Surgeon_FirstName": "George",
    "Credentials": "MD, FAAOS",
    "Website": "theadvancedspinecenter.com georgenaseef.com",
    "Video Link": "Dr Naseef V1:�https://www.dropbox.com/s/mii9kpr205nkd91/George%20S.%20Naseef%20V1.mov?dl=0",
    "Phone": "973-538-0900",
    "Practice_Name": "The Advanced Spine Center, Suite 201",
    "Address": "160 East Hanover Avenue 2nd Floor",
    "City": "Morristown",
    "State": "NJ",
    "Zip": "07960",
    "Specialty": "Spine Open Repair & Reconstructive Surgery",
    "Additional_Locations": "Rockaway|Bridgewater",
    "Photo_ID": "Naseef_George.jpg",
    "Description": "My genuine concern for each patient�s regard as an individual, combined with specialized cutting-edge training in my field and abundance of knowledge that comes with practicing orthopedic spine surgery for 16 years, allow me to offer outstanding care for my patients. Board-certified in orthopedic surgery and fellowship trained in spine surgery, I am fully committed to excellence in spinal care and place great emphasis on thorough evaluations and accurate diagnoses. I have extensive experience in treating a wide array of spinal conditions including cervical, lumbar and thoracic disc herniations; stenosis; traumatic injuries; scoliosis; spinal deformities; work-related spinal injuries; and tumors. I also perform complicated revision procedures for patients whose previous surgeries failed. I�m proud to be a seven-time New Jersey Monthly Top Doctor and the recipient of other honors such as the Patient�s Choice and Most Compassionate Doctors awards from vitals.com.\nI devote my practice to exploring non-operative measures before considering surgical options. My patients benefit from being listened to very carefully and assessed as a whole because sometimes diagnostic studies don�t reveal the full story. That�s why I take a thorough approach incorporating a patient�s comorbidities, medical history and social needs when generating the best possible treatment plan.\nI aim to relieve patients� pain and restore them to function at their maximal potential, allowing them to enjoy better qualities of life. Without a doubt, my greatest reward in surgical practice is witnessing my patients thrive under the care I provide for them. DR. GEORGE NASEEF is a Bone & Joint Select Surgeon \nhonoree for being one of New Jersey�s top spine open repair and reconstruction surgeons.",
    "Enhanced": "TRUE"
  },
  {
    "Surgeon_LastName": "Nguyen",
    "Surgeon_FirstName": "Hoan-Vu",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "732-660-6200",
    "Practice_Name": "Seaview Orthopaedic and Medical Associates",
    "Address": "1200 Eagle Avenue",
    "City": "Ocean",
    "State": "NJ",
    "Zip": "07712",
    "Specialty": "Spine Open Repair & Reconstructive Surgery, Spine Minimally Invasive Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Nitche",
    "Surgeon_FirstName": "Jason",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "732-840-7500",
    "Practice_Name": "Brielle Orthopedics at Rothman",
    "Address": "457 Jack Martin Blouevard",
    "City": "Brick",
    "State": "NJ",
    "Zip": "08724",
    "Specialty": "Shoulder Open Reconstructive & Replacement Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Orozco",
    "Surgeon_FirstName": "Fabio",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "800-321-9999",
    "Practice_Name": "Rothman Orthopaedic Institute",
    "Address": "2500 English Creek Avenue Building 1300",
    "City": "Egg Harbor Township",
    "State": "NJ",
    "Zip": "08234",
    "Specialty": "Hip Minimally Invasive Surgery, Knee Minimally Invasive Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Pess",
    "Surgeon_FirstName": "Gary",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "732-542-4477",
    "Practice_Name": "Central Jersey Hand Surgery",
    "Address": "2 Industrial Way West",
    "City": "Eatontown",
    "State": "NJ",
    "Zip": "07724",
    "Specialty": "Hand Minimally Invasive Surgery,Wrist Open Repair & Reconstructive Surgery,  Wrist Minimally Invasive Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Pollack",
    "Surgeon_FirstName": "Michael",
    "Credentials": "MD",
    "Website": "midjerseyortho.com",
    "Video Link": "Dr Pollack V1.3:�https://www.dropbox.com/s/87o6rsmdui4r6eg/Michael%20E.%20Pollack%201.3.mov?dl=0",
    "Phone": "908-782-0600",
    "Practice_Name": "MidJersey Orthopaedics",
    "Address": "8100 Wescott Drive Suite 101",
    "City": "Flemington",
    "State": "NJ",
    "Zip": "08822",
    "Specialty": "Shoulder Arthroscopic Surgery",
    "Additional_Locations": "",
    "Photo_ID": "Pollack_Michael.jpg",
    "Description": "As an orthopedic surgeon, I strive to exhaust conservative treatment measures, including injections, home exercises, physical therapy, rest and activity modification, for all my patients prior to recommending and pursuing surgical treatments. My approach to surgery is always as a last resort for my patients. When necessary, I employ the most current and minimally invasive techniques to minimize pain and accelerate recovery. I specialize in shoulder arthroscopy including rotator cuff repairs, labral repairs, biceps tenodesis, SLAP repairs and shoulder stabilizations. I also specialize in anterior cruciate ligament reconstruction surgery, total and reverse shoulder arthroplasty and knee arthroplasty.\nI received my training in orthopedic surgery in New York City at St. Luke�s-Roosevelt Hospital Center and pursued advanced fellowship training in shoulder and arthroscopic surgery at California Pacific Medical Center in San Francisco. I�ve been in practice for more than 15 years and am most proud of the thousands of successful surgeries I have performed and the tens of thousands of patients I�ve cared for over that period of time. I am also proud to have been named a New Jersey Monthly Top Doctor three times. I am very clear, compassionate and direct with my patients and treat them as I�d treat a family member or friend. My favorite part of my job is restoring my patients to a high quality of life and helping them return to their desired recreational activities and passions. In my free time I enjoy spending as much time as possible with my wife, two teenage daughters and dog Cocoa. I also enjoy exercise and travel. DR. MICHAEL E. POLLACK is a Bone & Joint Select Surgeon honoree for being one of New Jersey�s top shoulder arthroscopic surgeons.",
    "Enhanced": "TRUE"
  },
  {
    "Surgeon_LastName": "Polonet",
    "Surgeon_FirstName": "David",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "732-938-6090",
    "Practice_Name": "University Orthopaedic Associates",
    "Address": "4810 Belmar Boulevard",
    "City": "Wall Township",
    "State": "NJ",
    "Zip": "07753",
    "Specialty": "Trauma Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Post",
    "Surgeon_FirstName": "Zachary",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "609-573-3317",
    "Practice_Name": "Rothman Orthopaedic Institute",
    "Address": "2500 English Creek Avenue Building 1300",
    "City": "Egg Harbor Township",
    "State": "NJ",
    "Zip": "08234",
    "Specialty": "Hip Minimally Invasive Surgery, Knee Minimally Invasive Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Rajan",
    "Surgeon_FirstName": "Sivaram",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "551-999-6433",
    "Practice_Name": "Rajan Orthopaedics and Sports Medicine",
    "Address": "211 Essex Street Suite 101",
    "City": "Hackensack",
    "State": "NJ",
    "Zip": "07601",
    "Specialty": "Shoulder Minimally Invasive Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Reich",
    "Surgeon_FirstName": "Steven",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "732-422-1222",
    "Practice_Name": "Affiliated Orthopaedic Specialists",
    "Address": "2186 Route 27 Suite 1A",
    "City": "North Brunswick",
    "State": "NJ",
    "Zip": "08902",
    "Specialty": "Spine Minimally Invasive Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Reilly",
    "Surgeon_FirstName": "Mark",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "973-972-0681",
    "Practice_Name": "North Jersey Orthopaedic Institute",
    "Address": "140 Bergen Street D1610",
    "City": "Newark",
    "State": "NJ",
    "Zip": "07103",
    "Specialty": "Trauma Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Rieger",
    "Surgeon_FirstName": "Kenneth",
    "Credentials": "MD",
    "Website": "njspinecenter.com",
    "Video Link": "Dr Kenneth Reiger V2:�https://www.dropbox.com/s/n81eylgss3gl39x/Kenneth%20J.%20Rieger.mov?dl=0",
    "Phone": "973-635-0800",
    "Practice_Name": "New Jersey Spine Center",
    "Address": "40 Main Street",
    "City": "Chatham",
    "State": "NJ",
    "Zip": "07928",
    "Specialty": "Spine Open Repair & Reconstructive Surgery",
    "Additional_Locations": "",
    "Photo_ID": "Rieger_Kenneth.jpg",
    "Description": "Practicing spine surgery at New Jersey Spine Center this past 13 years has taught me the importance of the physician/patient relationship for successful outcomes. I embrace my patients with compassion, treat them with respect and provide enough information so they are educated, well prepared and comfortable as they embark on their journey with me.\nUtilizing current surgical techniques and emerging technology, I specialize in all aspects of spine surgery encompassing the cervical, thoracic and lumbar spine. Treating injuries that range from herniated discs to acute fractures and from tumors to scoliosis, I am conservative by nature and all non-operative options are exhausted. Every opinion I render must pass the ultimate test: is this what I would recommend for my own family?\nWhen I am not with my patients, I enjoy my time with my family, creating memories that will last a lifetime. In recent years I have participated in several triathlons, and in 2018, completed an IRONMAN 70.3 Triathlon.\nGrowing up in Pennsylvania, I attended Johns Hopkins University where I earned my B.S. in biomedical engineering. I obtained my medical degree from Columbia University Vagelos College of Physicians and Surgeons in New York City where I was invited to remain for an orthopedic residency and served as chief resident. I then participated in a fellowship program dedicated entirely to spine surgery at Norton Leatherman in Louisville, Kentucky. I have been recognized by Castle Connolly Ltd. as a Top Doctor: New York Metro Area and honored by my peers for nine consecutive years as a New Jersey Monthly Top Doctor. DR. KENNETH RIEGER is a Bone & Joint Select Surgeon honoree for being one of New Jersey�s top spine open repair and reconstruction surgeons.",
    "Enhanced": "TRUE"
  },
  {
    "Surgeon_LastName": "Rieger",
    "Surgeon_FirstName": "Mark A.",
    "Credentials": "MD, FAAOS",
    "Website": "pediatricorthopedics.com",
    "Video Link": "",
    "Phone": "973-538-7700",
    "Practice_Name": "The Pediatric Orthopedic Center, A Member of Consensus Health",
    "Address": "218 Ridgedale Avenue Suite 104",
    "City": "Cedar Knolls",
    "State": "NJ",
    "Zip": "07927",
    "Specialty": "Pediatric Surgery",
    "Additional_Locations": "Wayne|Springfield |Jersey City",
    "Photo_ID": "Rieger_Mark-A.jpg",
    "Description": "I love being a pediatric orthopedic surgeon. I have been in practice for 30 years, specializing in treating scoliosis and trauma, and performing arthroscopy and minimally invasive surgery. As I analyze each injury or condition, I determine how it is impacting a child�s life. This thoughtful analysis allows me to bring each patient to a level of health where they can enjoy life without pain. For me, surgery is always a last resort.\nAs a young man, I learned from my grandmother that when you change a child�s life, you change the world. Each day I am blessed to be able to care for children and change lives for the better. During my training, I had the privilege of being mentored by Dr. John Hall at Boston Children�s Hospital. He was a kind man who took care of children as if they were his own. He was one of the most skilled surgeons I have ever seen and every day I strive to be a little closer to the example he set.\nWhen children smile, they can become kids again and continue to enjoy their lives. My goal is to treat children so well that they can return to their regular life as soon as possible, and never have to think about me again. To me, that is the greatest gift I can give and that is my passion. DR. MARK A. RIEGER is a Bone & Joint Select Surgeon honoree for being one of New Jersey�s top pediatric orthopedic surgeons",
    "Enhanced": "TRUE"
  },
  {
    "Surgeon_LastName": "Rodricks",
    "Surgeon_FirstName": "David",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "732-840-7500",
    "Practice_Name": "Brielle Orthopedics at Rothman",
    "Address": "457 Jack Martin Blouevard Suite 7",
    "City": "Brick",
    "State": "NJ",
    "Zip": "08724",
    "Specialty": "Hip Arthroscopic Surgery, Knee Open Reconstructive & Replacement Surgery, Knee Arthroscopic Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Sagebien",
    "Surgeon_FirstName": "Carlos",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "732-537-0909 ext. 11",
    "Practice_Name": "University Orthopaedic Associates",
    "Address": "2 Wolrds Fair Drive",
    "City": "Somerset",
    "State": "NJ",
    "Zip": "08873",
    "Specialty": "Trauma Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Salvo, Jr",
    "Surgeon_FirstName": "John",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "800-321-9999",
    "Practice_Name": "Rothman Orthopaedics",
    "Address": "999 Route 73 North Suite 401",
    "City": "Marlton",
    "State": "NJ",
    "Zip": "08053",
    "Specialty": "Sports Medicine Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Sattel",
    "Surgeon_FirstName": "Andrew",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "856-983-4263",
    "Practice_Name": "Virtua Hand Surgery and Rehabilitation",
    "Address": "534 Lippincott Ave",
    "City": "Marlton",
    "State": "NJ",
    "Zip": "08053",
    "Specialty": "Hand Open Repair & Reconstructive Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Schwartz",
    "Surgeon_FirstName": "Mark",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "856-235-7080",
    "Practice_Name": "Burlington County Orthopaedic Specialists",
    "Address": "2059 Briggs Road Suite 304",
    "City": "Mount Laurel",
    "State": "NJ",
    "Zip": "08054",
    "Specialty": "Shoulder Open Reconstructive & Replacement Surgery, Rearfoot Open Repair & Reconstructive Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Seldes",
    "Surgeon_FirstName": "Richard M.",
    "Credentials": "MD",
    "Website": "facebook.com/RichardSeldesMD Twitter: @RichardSeldesMD",
    "Video Link": "Seldes:�https://www.dropbox.com/sh/n1khmd4ztt18af7/AACvkVR65UVZ4Al0B8aWTIpCa?dl=0�",
    "Phone": "201-343-3999",
    "Practice_Name": "",
    "Address": "401 Hackensack Avenue 10th Floor",
    "City": "Hackensack",
    "State": "NJ",
    "Zip": "07601",
    "Specialty": "Shoulder Arthroscopic Surgery, Hip Arthroscopic Surgery, Knee Arthroscopic Surgery",
    "Additional_Locations": "",
    "Photo_ID": "Seldes_Richard-M.jpg",
    "Description": "I am privileged and honored to be a physician and orthopedic surgeon. I feel it is one of the most important jobs in the world and I treat it as such. I have been in practice for 20 years and perform arthroscopic and total joint replacement on the hip, knee and shoulder. When I perform surgery on a patient, his or her life is in my hands and I treat that responsibility very seriously. I review each case beforehand, plan the procedure, make sure I have the appropriate equipment and staffing and get a good night�s sleep before the surgery. Most importantly, I plan for the unexpected and make sure I have what I need in unexpected circumstances.\nPatients can expect to be treated like a family member in my practice and will receive the utmost respect and professionalism from me and my staff. I will do my absolute best to help them get back to their lives, including returning to work, being able to walk or run again, or being able to carry their child or grandchild.\nWhile I have published many articles, won awards and been selected to healthcare leadership positions, my proudest accomplishments are being able to help patients return to their daily routines. Seeing them transform from being depressed because of pain to being happy and pain-free, or being able to walk again after being in a wheelchair, is very special to me. I truly love being a doctor. I could not see myself doing anything else. DR. RICHARD M. SELDES is a Bone & Joint Surgery Select honoree for being one of New Jersey�s top shoulder, hip and knee arthroscopic surgeons.",
    "Enhanced": "TRUE"
  },
  {
    "Surgeon_LastName": "Sergi",
    "Surgeon_FirstName": "Anthony",
    "Credentials": "DPM",
    "Website": "",
    "Video Link": "",
    "Phone": "732-494-5601",
    "Practice_Name": "Edison Foot and Ankle Care",
    "Address": "102 James Street Suite 301",
    "City": "Edison",
    "State": "NJ",
    "Zip": "08820",
    "Specialty": "Rearfoot Open Repair & Reconstructive Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Sirkin",
    "Surgeon_FirstName": "Michael",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "973-972-0681",
    "Practice_Name": "North Jersey Orthopedic Institute",
    "Address": "140 Bergen Street ACC D1610",
    "City": "Newark",
    "State": "NJ",
    "Zip": "07101",
    "Specialty": "Trauma Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Smith",
    "Surgeon_FirstName": "John",
    "Credentials": "DPM",
    "Website": "",
    "Video Link": "",
    "Phone": "609-924-8131",
    "Practice_Name": "Princeton Regional Foot and Ankle",
    "Address": "Princeton Orthopedic Associates 325 Princeton Ave",
    "City": "Princeton, NJ",
    "State": "NJ",
    "Zip": "08540",
    "Specialty": "Fore Mid Foot Open Repair & Reconstructive Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Spagnuola",
    "Surgeon_FirstName": "Christopher",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "732-660-6200",
    "Practice_Name": "Seaview Orthopaedic and Medical Associates",
    "Address": "1200 Eagle Avenue",
    "City": "Ocean",
    "State": "NJ",
    "Zip": "07712",
    "Specialty": "Sports Medicine Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Stamos",
    "Surgeon_FirstName": "Bruce",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "732-840-7500",
    "Practice_Name": "Brielle Orthopedics at Rothman",
    "Address": "457 Jack Martin Blouevard",
    "City": "Brick",
    "State": "NJ",
    "Zip": "08724",
    "Specialty": "Shoulder Minimally Invasive Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Strassberg",
    "Surgeon_FirstName": "Joshua A.",
    "Credentials": "MD, FAAOS",
    "Website": "pediatricorthopedics.com",
    "Video Link": "",
    "Phone": "973-538-7700",
    "Practice_Name": "The Pediatric Orthopedic Center, A Member of Consensus Health",
    "Address": "218 Ridgedale Avenue Suite 101",
    "City": "Cedar Knolls",
    "State": "NJ",
    "Zip": "07927",
    "Specialty": "Pediatric Surgery",
    "Additional_Locations": "Wayne|Springfield |Jersey City",
    "Photo_ID": "Strassberg_Joshua-A.jpg",
    "Description": "After having trained at New York Medical College, Children�s Hospital of Philadelphia and Boston Children�s Hospital, I returned to my home state of New Jersey to start my career in pediatric orthopedics.\nPatients should know that when they walk into The Pediatric Orthopedic Center they will receive some of the best pediatric orthopedic care in the tri-state area. I make every effort to ensure that my patients are comfortable and able to get back to the life and level of activity they had before as safely and as quickly as possible. My specialties include pediatric growth plate-sparing ACL reconstruction as well as additional pediatric sports procedures, pediatric trauma and pediatric hip preservation procedures. I typically take a conservative approach to treatment rather than jumping directly to a surgical solution. Children often can heal and thrive non-operatively in certain situations where an adult�s only option may be surgical.\nMy goal is to make patients and their families feel at ease. This includes having detailed conversations with them to ensure they understand all potential options that are available and to discuss the risks and benefits of each treatment option. I encourage patients to ask as many questions as they need so they leave feeling fully informed. It�s extremely satisfying when I receive personal cards or drawings from my patients that show their gratitude for my compassionate care. My greatest joy, however, is to see my patients fully recovered and returned to their sports and other activities.  DR. JOSHUA A. STRASSBERG is a Bone & Joint Select Surgeon honoree for being one of New Jersey�s top pediatric orthopedic surgeons.",
    "Enhanced": "TRUE"
  },
  {
    "Surgeon_LastName": "Therrien",
    "Surgeon_FirstName": "Philip",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "732-390-1160",
    "Practice_Name": "Pediatric Orthopaedic Associates",
    "Address": "585 Cranbury Road",
    "City": "East Brunswick",
    "State": "NJ",
    "Zip": "08816",
    "Specialty": "Pediatric Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Tucker",
    "Surgeon_FirstName": "Bradford",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "800-321-9999",
    "Practice_Name": "Rothman Orthopaedics Institute",
    "Address": "2500 English Creek Avenue Building 1300",
    "City": "Egg Harbor Township",
    "State": "NJ",
    "Zip": "08234",
    "Specialty": "Sports Medicine Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Vasen",
    "Surgeon_FirstName": "Arthur",
    "Credentials": "MD, PhD",
    "Website": "",
    "Video Link": "",
    "Phone": "732-660-6200",
    "Practice_Name": "Seaview Orthopaedic and Medical Associates",
    "Address": "1200 Eagle Avenue",
    "City": "Ocean",
    "State": "NJ",
    "Zip": "07712",
    "Specialty": "Elbow Surgery, Hand Open Repair & Reconstructive Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Wanich",
    "Surgeon_FirstName": "Tony",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "973-595-7779",
    "Practice_Name": "High Mountain Orthopedics",
    "Address": "342 Hamburg Turnpike Suite 205",
    "City": "Wayne",
    "State": "NJ",
    "Zip": "07470",
    "Specialty": "Sports Medicine Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Wittig",
    "Surgeon_FirstName": "James",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "833-292-2663",
    "Practice_Name": "Atlantic Orthopedic Oncology and Sarcoma",
    "Address": "100 Madison Avenue Carol Simon Cancer Center Morristown Medical Center",
    "City": "Morristown",
    "State": "NJ",
    "Zip": "07960",
    "Specialty": "Shoulder Open Reconstructive & Replacement Surgery, Oncologic Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  {
    "Surgeon_LastName": "Zornitzer",
    "Surgeon_FirstName": "Matthew",
    "Credentials": "MD",
    "Website": "",
    "Video Link": "",
    "Phone": "973-669-5600",
    "Practice_Name": "Summit Medical Group",
    "Address": "1500 Pleasant Valley Way Suite 101",
    "City": "West Orange",
    "State": "NJ",
    "Zip": "07052",
    "Specialty": "Elbow Surgery, Hand Arthroscopic Surgery, Wrist Open Repair & Reconstructive Surgery",
    "Additional_Locations": "",
    "Photo_ID": "",
    "Description": "",
    "Enhanced": ""
  },
  
]
    }

  },
  created() {


    this.$store.dispatch('LOADING', true);

    

    const uri = ENV_URL + this.$route.path;
    this.$store.dispatch('SUPER_ENTRIES_CREATE_TYPE', {uri: uri}).then(() => {

      this.$store.dispatch('LOADING', false);

    })

  },

  computed: {
    user() {
      return this.$store.state.user;
    },
    entryForm() {
      return this.$store.state.entryForm;
    },
    entry() {
      return this.$store.state.entry;
    },
  },

  mounted() {

  },

  methods: {
    formSubmitted(e) {
      var laravelAuthToken = localStorage.getItem('laravelAuthToken');
      let authHeaders = {
        headers: {"Authorization" : `Bearer ${laravelAuthToken}`}
      };
      
      const item_data = {
        entry_form_fields: this.entryForm.fields,
        categories: this.entry.categories,
      }
      
      // /super/contests/{contest}/entries/{id}/update
      let uri = ENV_URL + this.$route.path;
       console.log("the uri is" + uri);
       axios.post(uri, item_data, authHeaders).then((response) => {
        this.$store.dispatch('LOADING', false);
        alert('Your item was created!');
        console.dir(response)
        this.$router.push({ path: '/super/listings/' + response.data.entry.id})
      })
      .catch((error) => {
        console.log('app error: ', error.message);
        this.$store.dispatch('LOADING', false);
        console.log("the errors is ")
        alert(error.message);
      })
      
    },

    load_entry_data(){
      console.dir(this.myentrydata)
    },

  },

}
</script>

<style lang="scss">

</style>
